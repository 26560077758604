export const SUBSCRIPTION_TYPE_DATE_INTERVAL = 'date_interval'
export const SUBSCRIPTION_TYPE_DAYS = 'interval_in_days'

export const SUBSCRIPTION_TYPE_TYPE_DEFAULT = SUBSCRIPTION_TYPE_DATE_INTERVAL

export default {
  computed: {
    subscriptionTypeValues () {
      return [
        {
          id: SUBSCRIPTION_TYPE_DATE_INTERVAL,
          title: this.$t('eshop.subscription_type.date_interval')
        },
        {
          id: SUBSCRIPTION_TYPE_DAYS,
          title: this.$t('eshop.subscription_type.interval_in_days')
        }
      ]
    }
  },
  methods: {
    subscriptionTypeById (id) {
      var item = id
      this.subscriptionTypeValues.forEach(function (subscription) {
        if (id === subscription.id) {
          item = subscription
        }
      })
      return item
    }
  }
}
